@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
    --primary-bg-colour: #FDFDFD;
    --secondary-bg-colour: #FFFFFF;
    --alternate-bg-colour: #e3f2fd;
    --dark-bg-colour: #002d80;
    --primary-text-colour: #1a1a1a;
    --secondary-text-colour: #0e0e0e;
    --tertiary-text-colour: #3f51bf;
    --alternate-text-colour: #FFFFFF;
    --logo-colour-dark: #0153ec;
    --logo-colour-light: #00d0f8;
    --telstra-colour: #f44e1a;
    --cisco-colour: #049fd9;
    --microsoft-colour: #ffba08;
    --navman-colour: #99c221;
    --breakpoint-sx: 0px;
    --breakpoint-sm: 600px;
    --breakpoint-md: 900px;
    --breakpoint-lg: 1200px;
    --breakpoint-xl: 1536px;
}

body {
    background-color: var(--primary-bg-colour);
    font-family: 'Open Sans', Calibri, Inter, 'Segoe UI', sans-serif;
    height: 100%;
    overflow-x: hidden;
    cursor: default;
}

h1 {
    font-size: 4rem;
    font-weight: 500;
    text-align: center;
    font-family: 'Be Vietnam Pro', sans-serif;
}

h2 {
    font-weight: 500;
    font-size: 1.5rem;
    text-align: center;
}

h3 {
    font-weight: 500;
    font-size: 1.5rem;
    color: var(--tertiary-text-colour);
    text-align: left;
}

p,
li,
ul>li>ul>li {
    font-size: 1.25rem;
}

a {
    cursor: pointer;
    color: #3f51bf;
    text-decoration: none;
    transition: 0.15s;
}

a:hover {
    text-decoration: underline;
}

.navman {
    color: #98c220;
}

header {
    height: 7%;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-shadow: #32325d40 0px 2px 5px -1px, #0000004d 0px 1px 3px -1px;
    background-color: var(--secondary-bg-colour);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10;
    background-color: var(--secondary-bg-colour);
}

#header-nav {
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: left;
    padding-left: 1%;
}

.nav-icon {
    height: 50%;
    width: fit-content;
    margin: auto;
    margin-left: 1%;
    cursor: pointer;
    color: var(--primary-text-colour);
}

#search-icon {
    height: 40%;
    width: fit-content;
}

#search-bar-container {
    margin: auto 1%;
}

#sign-in-button {
    height: 30%;
    max-height: 3rem;
    padding: 10% auto;
    margin: auto;
    white-space: nowrap;
    overflow: hidden;
}

#header-logo {
    height: 80%;
    margin: auto;
    margin-right: 2%;
    cursor: pointer;
}

#sign-in-container {
    width: 20rem;
    height: 24rem;
    background-color: var(--primary-bg-colour);
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    position: absolute;
    left: 50%;
    top: 40%;
    margin-left: -10rem;
    margin-top: -10rem;
}

#sign-in-container h1 {
    margin: auto;
}

#sign-in-container p {
    margin: auto;
    font-size: 0.8rem;
    padding: 0 1.5rem;
    text-align: center;
    padding-bottom: 1rem;
}

#outlined-basic {
    margin: auto;
    margin-right: 2rem;
    width: auto;
}

.MuiDrawer-paper {
    width: 25rem;
}

.nav-button {
    color: #FFFFFF;
}

.MuiListItemText-root {
    font-size: 2.75rem;
    font-family: 'Be Vietnam Pro', sans-serif;
}

.splash {
    height: 90%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    opacity: 0.85;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0%;
    margin-top: 4%;
}

.sub-splash {
    height: 45%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 7vh;
    opacity: 0.85;
}

.splash>h1 {
    font-size: 5vw;
    font-weight: 400;
    color: var(--alternate-text-colour);
    text-shadow: 0px 0px 5px rgba(0, 0, 0, 1);
    text-align: left;
    margin: auto 2%;
}

/* .splash>h1>span {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    padding: 5px;
    box-shadow: #32325d40 0px 2px 5px -1px, #0000004d 0px 1px 3px -1px;
} */

.sub-splash>h1 {
    font-size: 3vw;
    font-weight: 400;
    color: var(--alternate-text-colour);
    text-shadow: 0px 0px 5px rgba(0, 0, 0, 1);
    text-align: center;
    margin: auto 2%;
}

/* .sub-splash>h1>span {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    padding: 5px;
    box-shadow: #32325d40 0px 2px 5px -1px, #0000004d 0px 1px 3px -1px;
} */

.section {
    height: fit-content;
    box-sizing: border-box;
    padding-top: 2%;
    padding-bottom: 5%;
}

#home-splash {
    background-image: url(../Images/Splash\ -\ Home\ 2\ -\ blur\ v1.jpg);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#home-splash-header {
    height: fit-content;
    background-image: linear-gradient(#00000000, #00000049, #00000067, #00000049, #00000000);
    padding: 2% 0;
}

#home-splash-header>h1 {
    font-size: 5vw;
    font-weight: 500;
    color: var(--alternate-text-colour);
    text-shadow: 0px 0px 3px #0000006c;
    text-align: left;
    margin: auto 2%;
    margin-left: 5%;
}

.grid-container {
    width: 60%;
    margin: 0 auto;
}

.section-h1,
.section-h2 {
    color: var(--tertiary-text-colour);
    margin: 0;
    margin-top: 2%;
    margin-bottom: 3%;
}

#welcome-img {
    width: 100%;
    height: fit-content;
    margin: auto;
}

#home-services {
    background-color: #263F86;
}

.home-service {
    width: 90%;
    height: 90%;
    background-color: var(--primary-bg-colour);
    border-radius: 5px;
    cursor: pointer;
    box-shadow: #32325d40 0px 2px 5px -1px, #0000004d 0px 1px 3px -1px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: 0.15s;
}

.home-service:hover {
    background-color: var(--secondary-bg-colour);
    box-shadow: #ffffff85 0px 0px 10px 2px;
}

#home-services-header {
    color: var(--secondary-text-colour);
}

.home-service-svg {
    max-width: 80%;
    max-height: 50%;
    margin: auto;
}

#home-testimonials {
    padding-bottom: 5%;
    background-color: #e97451;
}

#home-testimonails>h1 {
    color: #1a2e61;
}

.testimonial {
    height: 90%;
    width: 90%;
    border-radius: 5px;
    margin: 0 auto;
    box-shadow: #32325d40 0px 2px 5px -1px, #0000004d 0px 1px 3px -1px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 5% auto;
    margin-bottom: 7%;
    background-color: var(--secondary-bg-colour);
}

.testimonial-contact-info {
    margin-bottom: 0;
    margin-top: auto;
}

.customer-logo {
    height: 100px;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    width: 100%;
    margin: 0;
    padding: 1% 0;
    border-radius: 5px;
}

#blairs-logo {
    background-color: #000000;
}

.customer-logo>img {
    height: 90%;
    width: auto;
    max-height: 100%;
    max-width: 100%;
    margin: auto;
}

.testimonial>h2,
.testimonial>h3,
.testimonial>p,
.testimonial-contact-info>p {
    padding: 0 5%;
    margin: 1%;
}

.testimonial>h2 {
    font-size: 1.75rem;
}

.testimonial>h3 {
    font-size: 1.5rem;
}

.testimonial>p,
.testimonial-contact-info>p {
    font-size: 1.25rem;
}

.team-member {
    height: 100%;
    width: 100%;
    border-radius: 5px;
    margin: 0 auto;
    box-shadow: #32325d40 0px 2px 5px -1px, #0000004d 0px 1px 3px -1px;
    display: flex;
    flex-direction: column;
    margin: 2% auto;
}

.team-member-img {
    width: 100%;
    margin: 0 auto;
    border-radius: 5px;
}

.team-member-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5%;
    margin-top: 0;
    margin-bottom: auto;
}

.team-member-content>h2 {
    font-size: 1.25rem;
    margin: 0;
}

.team-member-content>h3,
.team-member-content>a {
    font-size: 1.2rem;
    text-align: center;
    margin: 0;
}

#team-splash {
    background-image: url(../Images/Splash\ -\ Our\ Team\ 2.jpg);
}

#telstra-splash {
    background-color: var(--telstra-colour);
}

#telstra-tipt,
#telstra-mobile {
    background-color: #F5EDE2;
}

#cisco-splash {
    background-color: var(--cisco-colour);
}

#cisco-content-1,
#cisco-content-3 {
    background-color: #E7F4F4;
}

#cisco-content-2 {
    background-color: #F6F7F8;
}

#microsoft-splash {
    background-color: var(--microsoft-colour);
}

#microsoft-content-2 {
    background-color: #E1E7FC;
}

#microsoft-content-3 {
    background-color: #f5f5f5;
}

#navman-splash {
    background-color: var(--navman-colour);
}

#navman-content-2 {
    background-color: #EBFBCF;
}

#contact-us-splash {
    background-image: linear-gradient(135deg, var(--logo-colour-dark), var(--logo-colour-light));
}

#contact-us-map {
    width: 500px;
    height: 100%;
}

#privacy-splash {
    background-image: linear-gradient(135deg, var(--logo-colour-dark), var(--logo-colour-light));
}

#terms-splash {
    background-image: linear-gradient(135deg, var(--logo-colour-dark), var(--logo-colour-light));
}

#faq-splash {
    background-image: linear-gradient(135deg, var(--logo-colour-dark), var(--logo-colour-light));
}

#not-found-splash {
    background-color: #ec0000;
}

.faq-container {
    height: 100%;
    min-height: 200px;
    width: 100%;
    border-radius: 5px;
    padding: 2%;
    box-sizing: border-box;
}

#faq-telstra {
    background-color: var(--microsoft-colour);
}

#faq-cisco {
    background-color: var(--cisco-colour);
}

#faq-microsoft {
    background-color: #f96449;
}

#faq-navman {
    background-color: var(--navman-colour);
}

.faq {
    color: var(--alternate-text-colour);
    text-shadow: 0px 0px 5px rgba(0, 0, 0, 1);
}

.faq-container>p>a {
    color: var(--primary-text-colour);
    font-weight: 500;
}

.content-h1 {
    text-align: left;
    margin: auto;
    margin-bottom: 1%;
}

.content-h2 {
    text-align: left;
    margin: auto;
}

.content-h3 {
    font-size: small;
    margin: auto;
    margin-top: 5%;
}

.content-p {
    margin: auto;
    margin-bottom: 1%;
    margin-top: 1%;
}

.content-img {
    width: auto;
    max-width: 100%;
    height: fit-content;
}

#microsoft-fig-1 {
    height: auto;
    width: 100%;
}

#telstra-services-logo {
    background-image: url(../Images/Telstra\ Partner\ -\ Logo.PNG);
    height: 90%;
    min-height: 200px;
    width: 90%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin: auto;
}

#cisco-services-logo {
    background-image: url(../Images/Cisco\ Meraki\ -\ Logo.avif);
    height: 90%;
    min-height: 200px;
    width: 90%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin: auto;
}

#microsoft-services-logo {
    background-image: url(../Images/Microsoft\ Logo.svg);
    height: 90%;
    min-height: 200px;
    width: 90%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin: auto;
}

#navman-services-logo {
    background-image: url(../Images/Teletrac\ Navman\ Logo.svg);
    height: 90%;
    min-height: 200px;
    width: 90%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin: auto;
}

#cisco-fig-1 {
    background-image: url(../Images/Cisco\ -\ Figure\ 1.svg);
    height: 100%;
    min-height: 200px;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin: auto;
}

#cisco-fig-2 {
    background-image: url(../Images/Cisco\ -\ Figure\ 2.svg);
    height: 100%;
    min-height: 200px;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin: auto;
}

#cisco-fig-3 {
    background-image: url(../Images/Cisco\ -\ Figure\ 3.svg);
    height: 100%;
    min-height: 200px;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin: auto;
}

#cisco-fig-4 {
    background-image: url(../Images/Cisco\ -\ Figure\ 4.svg);
    height: 100%;
    min-height: 200px;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin: auto;
}

footer {
    background-color: var(--secondary-bg-colour);
    border-top: 1px solid #dadada;
    padding: 1% 0;
}

#footer-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#footer-container>p {
    margin: 0 auto;
    font-size: 0.95rem;
    margin-bottom: 0.2vw;
}

#footer-container>p>a {
    color: #000000;
}

#footer-social {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

#footer-container>img {
    height: 2rem;
    margin-bottom: 0.1vw;
}

@media all and (max-width: 600px) {

    header {
        min-height: 42px;
    }

    .splash {
        min-height: 480px;
    }

    #home-splash-header>h1 {
        font-size: 10vw;
    }

    .sub-splash {
        min-height: 240px;
    }

    .sub-splash>h1 {
        font-size: 8vw;
    }

    h1,
    .content-h1 {
        font-size: 1.75rem;
    }

    h2,
    h3 {
        font-size: 1.5rem;
        text-align: center;
    }

    p,
    li {
        font-size: 1rem;
    }

    .MuiDrawer-paper {
        max-width: 100vw;
    }

    .section-h1,
    .section-h2 {
        margin-top: 5%;
    }

    .testimonial>h2 {
        font-size: 1.25rem;
    }

    .testimonial>h3 {
        font-size: 1rem;
    }

    .content-p,
    .content-h3,
    .testimonial>p {
        font-size: 0.75rem;
    }

    .grid-container {
        width: 85%;
    }

}

@media all and (min-width: 601px) {

    header {
        min-height: 42px;
    }

    .splash {
        min-height: 480px;
    }

    #home-splash-header>h1 {
        font-size: 7vw;
    }

    .sub-splash {
        min-height: 240px;
    }

    .sub-splash>h1 {
        font-size: 5vw;
    }

    h1,
    .content-h1 {
        font-size: 1.75rem;
    }

    h2,
    h3 {
        font-size: 1.5rem;
        text-align: center;
    }

    p,
    li {
        font-size: 1rem;
    }

    .testimonial>h2 {
        font-size: 1.25rem;
    }

    .testimonial>h3 {
        font-size: 1rem;
    }

    .content-p,
    .content-h3,
    .testimonial>p {
        font-size: 0.75rem;
    }

    .grid-container {
        width: 85%;
    }

}

@media all and (min-width: 900px) {

    header {
        min-height: 63px;
    }

    .splash {
        min-height: 720px;
    }

    #home-splash-header>h1 {
        font-size: 7vw;
    }

    .sub-splash {
        min-height: 360px;
    }

    .sub-splash>h1 {
        font-size: 5vw;
    }

    h1,
    .content-h1 {
        font-size: 1.75rem;
    }

    h2,
    h3 {
        font-size: 1.5rem;
        text-align: left;
    }

    p,
    li {
        font-size: 1rem;
    }

    .testimonial>h2 {
        font-size: 1.25rem;
    }

    .testimonial>h3 {
        font-size: 1rem;
    }

    .content-p,
    .content-h3,
    .testimonial>p {
        font-size: 0.75rem;
    }

    .grid-container {
        width: 80%;
    }

}

@media all and (min-width: 1200px) {

    header {
        min-height: 84px;
    }

    .splash {
        min-height: 960px;
    }

    #home-splash-header>h1 {
        font-size: 5vw;
    }

    .sub-splash {
        min-height: 480px;
    }

    .sub-splash>h1 {
        font-size: 3vw;
    }

    h1,
    .content-h1 {
        font-size: 2rem;
    }

    h2,
    h3 {
        font-size: 1.75rem;
        text-align: left;
    }

    p,
    li {
        font-size: 1.25rem;
    }

    .testimonial>h2 {
        font-size: 1.5rem;
    }

    .testimonial>h3 {
        font-size: 1.25rem;
    }

    .content-p,
    .content-h3,
    .testimonial>p {
        font-size: 1rem;
    }

    .grid-container {
        width: 80%;
    }

}

@media all and (min-width: 1536px) {

    header {
        min-height: 107px;
    }

    .splash {
        min-height: 864px;
    }

    #home-splash-header>h1 {
        font-size: 5vw;
    }

    .sub-splash {
        min-height: 432px;
    }

    .sub-splash>h1 {
        font-size: 3vw;
    }

    h1,
    .content-h1 {
        font-size: 4rem;
    }

    h2,
    h3 {
        font-size: 2rem;
        text-align: left;
    }

    p,
    li {
        font-size: 1.5rem;
    }

    .testimonial>h2 {
        font-size: 1.75rem;
    }

    .testimonial>h3 {
        font-size: 1.5rem;
    }

    .content-p,
    .content-h3,
    .testimonial>p {
        font-size: 1.25rem;
    }

    .footer-section>p {
        font-size: 1rem;
    }

    .grid-container {
        width: 60%;
    }

}